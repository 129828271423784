import './App.css';
import React from 'react';
import { Button, Container, Row } from 'react-bootstrap'
import { BsDownload } from "react-icons/bs";
import { Link } from 'react-router-dom';

class Resume extends React.Component {
    render() {
        return <>
            <Container>
                <Row className="header_normal" style={{ paddingTop: '1%', paddingBottom: '1%' }}>
                    My Resume
                </Row>
                <Row style={{ paddingBottom: '1%' }}>
                    <p>Please send me a business email to <Link href = "mailto: bloomestjc@gmail.com">bloomestjc@gmail.com</Link> for my most recent resume.</p>
                </Row>
            </Container>
        </>
    }
}

export default Resume;