import './App.css';
import React from 'react';
import { Container, Row, Image, Card } from 'react-bootstrap';
import dirImage from "./assets/dir.jpg";

class About extends React.Component {
    render() {
        return <>
            <Container>
                <Row className="header_normal" style={{ paddingTop: '1%', paddingBottom: '1%' }} id="top">
                    About This Website
                </Row>
                <Row>
                    <p>
                        This is a full-stacked web application that also makes use of a database.
                        This web app combines a React frontend with a Flask backend deployed altogether to <a href="https://heroku.com" rel="noreferrer" target="_blank">Heroku</a>.
                        The Flask app serves as the api route for the backend while the React app serves the main page as well as some routings.
                        It also makes use of a psql database.
                    </p>
                </Row>
                <Row>
                    <p style={{ color: 'red' }}>
                        Please note that this is not a comprehensive tutorial, but rather a documentation of how this website was made.
                        In other words, this website was not intended to be a perfect web application, but rather an "easy to make but get the job done" website.
                        Please also note that everything here was developed and run on my local computer which is running Ubuntu 18.04 without using any container.
                        If you have any question, feel free to reach me out by sending a message from the home page.
                        I will be happy to help as best as I can.
                    </p>
                </Row>
                <Row>
                    <Card className="card_toc" style={{ marginTop: '5px', marginBottom: '1%', paddingBottom: '0%' }}>
                        <Card.Body>
                            <Card.Title>
                                <div style={{ maxWidth: 250 }}>
                                    Table of Contents
                                </div>
                            </Card.Title>
                            <ol>
                                <li><a href="#backend">Flask Backend</a></li>
                                <li><a href="#frontend">React Frontend</a></li>
                                <li><a href="#deploy">Deploying to Heroku</a></li>
                                <li><a href="#additions">Additional Things</a></li>
                                <li><a href="#final">Final Notes</a></li>
                            </ol>
                        </Card.Body>
                    </Card>
                </Row>
                <Row>
                    <p>
                        You can easily start by creating a folder with <code>api.py</code> in it along with another folder for the <a href="https://reactjs.org/docs/create-a-new-react-app.html" rel="noreferrer" target="_blank">React app</a>.
                        The whole directory tree will look like this (not including some of the folders that are too tedious as seen in the linux tree command: <code>tree -I "node_modules|venv|assets|public|__pycache__|static|app.py|design.md|*.png" .</code>).
                    </p>
                </Row>
                <Row>
                    <Image src={dirImage} style={{ width: '100%' }} />
                </Row>
                <Row className="sub_header_normal" id="backend">
                    Flask Backend
                </Row>
                <Row>
                    The backend basically consists of:
                </Row>
                <Row>
                    <ul>
                        <li><a href="https://restfulapi.net/" rel="noreferrer" target="_blank">APIs</a></li>
                        <li><a href="https://flask.palletsprojects.com/en/1.1.x/" rel="noreferrer" target="_blank">Flask</a></li>
                        <li><a href="https://flask-sqlalchemy.palletsprojects.com/en/2.x/" rel="noreferrer" target="_blank">SQLAlchemy</a></li>
                        <li><a href="https://flask-limiter.readthedocs.io/en/stable/" rel="noreferrer" target="_blank">Flask Rate Limiter</a></li>
                        <li><a href="https://github.com/GoogleCloudPlatform/flask-talisman" rel="noreferrer" target="_blank">HSTS using Flask Talisman (turned off)</a></li>
                    </ul>
                </Row>
                <Row>
                    <p>Here is the <code>api.py</code>:</p>
                </Row>
                <Row>
                    <pre style={{ backgroundColor: '#ffe8a8', width: '100%' }}>
                        <code>
                            {be_code}
                        </code>
                    </pre>
                </Row>
                <Row>
                    <p>
                        *Note: please refer to <a href="https://docs.appery.io/docs/apiexpress-databaseconnection-heroku-postgres" rel="noreferrer" target="_blank">this</a> for setting up psql on Heroku.
                    After everything has been launch, simply run <code>db.create_all()</code> on Heroku's python.
                    </p>
                    <p>
                        **Note: it is a bad practice to keep all the handlers, middlewares, business logics, and etc. in one file like this.
                    </p>
                </Row>
                <Row className="sub_header_normal" id="frontend">
                    React Frontend
                </Row>
                <Row>
                    <p>
                        The frontend was made purely in React with mainly <a href="https://react-bootstrap.github.io/" rel="noreferrer" target="_blank">React Bootstrap</a> for styling and <a href="https://reactrouter.com/" rel="noreferrer" target="_blank">React Router</a> for navigation.
                        The final product is, indeed, what you are seeing right now.
                    </p>
                </Row>
                <Row className="sub_header_normal" id="deploy">
                    Deploying to Heroku
                </Row>
                <Row>
                    <p>
                        Once you have everything ready to go, it is time to deploy the app to Heroku.
                        In order to do so, you need a <code>Procfile</code> as well as a <code>requirements.txt</code>.
                        If you are using a virtual environment like I did, simply run this to generate the <code>requirements.txt</code>:
                    </p>
                </Row>
                <Row>
                    <pre style={{ backgroundColor: '#ffe8a8', width: '100%' }}>
                        <code>
                            {'pip freeze > requirements.txt'}
                        </code>
                    </pre>
                </Row>
                <Row>
                    <p>
                        Make a new file called <code>Procfile</code> and simply add this to the file:
                    </p>
                </Row>
                <Row>
                    <pre style={{ backgroundColor: '#ffe8a8', width: '100%' }}>
                        <code>
                            {'web: gunicorn api:app'}
                        </code>
                    </pre>
                </Row>
                <Row>
                    <p>
                        *Note: the word <code>api</code> refers to the name of your python application, which is <code>api.py</code> in my case.
                    </p>
                </Row>
                <Row className="sub_header_normal" id="additions">
                    Additional Things
                </Row>
                <Row>
                    <p>
                        This website uses the <a href="https://devcenter.heroku.com/articles/automated-certificate-management" rel="noreferrer" target="_blank">ACM</a> ceritificate from Heroku and is redirected from a <a href="https://domains.google/" rel="noreferrer" target="_blank">Google domain</a>.
                    </p>
                </Row>
                <Row className="sub_header_normal" id="final">
                    Final Notes
                </Row>
                <Row>
                    <p>
                        Again, this is not a comprehensive tutorial, but rather a documentation. If you have any question, feel free to reach me out. I will try my best to answer your questions.
                    </p>
                </Row>
                <Row style={{ paddingTop: '2%', justifyContent: 'center' }}>
                    <p>
                        <a href="#top">Top</a>
                    </p>
                </Row>
                <Row>
                    <div style={{ paddingBottom: '10%' }}></div>
                </Row>
            </Container>
        </>
    }
}

const be_code = `from flask import Flask, request, g
from flask_limiter import Limiter
from flask_sqlalchemy import SQLAlchemy
from flask_limiter.util import get_remote_addresss

app = Flask(__name__, static_folder="client/build", static_url_path="")
app.config['SQLALCHEMY_DATABASE_URI']='YOUR_POSTGRES_SQL_URI_FROM_ENV_YAML_FILE'

db = SQLAlchemy(app)

#Default limiter
limiter = Limiter(
app,
key_func=get_remote_address,
default_limits=["1000 per day", "200 per hour"]
)

#Class for comment table
class Comment(db.Model):
__tablename__ = "comment"

_id = db.Column(db.Integer, primary_key=True, autoincrement=True)
name = db.Column(db.String(100), nullable=False)
email = db.Column(db.String(100))
comment = db.Column(db.String(250))

def __init__(self, name, email=None, comment=None):
    self.name = name
    self.email = email
    self.comment = comment

#Class for projects table
class Project(db.Model):
__tablename__ = "projects"

_id = db.Column(db.Integer, primary_key=True, autoincrement=True)
title = db.Column(db.String(100), nullable=False)
description = db.Column(db.String(500))
link = db.Column(db.String(250))
languages = db.Column(db.String(250))

def __init__(self, title, description=None, link=None, languages=None):
    self.title = title
    self.description = description
    self.link = link
    self.languages = languages

@app.route('/')
def index():
return app.send_static_file('index.html')

#This solved the React Router problem on client side
@app.errorhandler(404)
def not_found(e):
return app.send_static_file('index.html')

#Comment API
@app.route('/api/comment', methods = ['POST'])
@limiter.limit("1/second", override_defaults=False)
def send_comment():
if request.json.get('name', '') != '':
    _id = db.Column(db.Integer, primary_key=True, autoincrement=True)
    name = request.json.get('name', '')
    email = request.json.get('email', None)
    comment = request.json.get('comment', None)
    
    new_data = Comment(name, email, comment)
    db.session.add(new_data)
    db.session.commit()
    
    return {{'message': 'comment successfully added'}}, 200

return {{'message': 'failed to add comment'}}, 400

#Projects API
@app.route('/api/projects', methods = ['GET'])
def get_projects():
    projects = Project.query.all()
    results = [
        {
            "title": project.title,
            "description": project.description,
            "link": project.link,
            "languages": project.languages
        } for project in projects]

    return {"count": len(results), "projects": results}, 200

#Project API
@app.route('/api/projects/<project_id>', methods = ['GET'])
def get_project(project_id):
    project = Project.query.get_or_404(project_id)

    response = {
        "title": project.title,
        "description": project.description,
        "link": project.link,
        "languages": project.languages
    }
    return {"message": "success", "project": response}, 200
    
if __name__=="__main__":
    db.create_all()
    app.run(debug=False)`

export default About;