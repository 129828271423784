import './App.css';
import React from 'react';
import { Container, Button, Card, Row } from 'react-bootstrap';
import { GoMarkGithub } from "react-icons/go";

const failed_screen = <>
    <Container className="something_wrong" style={{ paddingTop: '10%' }}>
        <div>
            <p>
                Oops! Something went wrong.
            </p>
            <p>
                Please try again later.
            </p>
        </div>
    </Container>
</>

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project_list: [],
        };
    }

    componentDidMount() {
        fetch('api/projects', {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    project_list: this.state.project_list.concat(res.projects),
                });
            })
    }

    render() {
        let project_ls_temp = [];
        let i = 1;

        for (const project of Object.values(this.state.project_list)) {
            project_ls_temp.push(
                <Card style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} key={i}>
                    <Card.Body>
                        <Card.Title>
                            <div style={{ maxWidth: 250 }}>
                                {project.title}
                            </div>
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{project.languages}</Card.Subtitle>
                        <div style={{ paddingBottom: '1%' }}>
                            {project.description}
                        </div>
                        <div className="align-items-center">
                            <Button disabled={project.link === ""} href={project.link} target="_blank"><GoMarkGithub size={25} />  Code</Button>
                        </div>
                    </Card.Body>
                </Card>
            )
            i = i + 1;
        }

        return <>
            <Container>
                <Row className="header_normal" style={{ paddingTop: '1%', paddingBottom: '1%' }}>
                    Project List
                </Row>
                {project_ls_temp.length === 0 ?
                    <>
                        <Row>
                            {failed_screen}
                        </Row>
                    </>
                    :
                    <>
                        <Row style={{ paddingBottom: '1%' }}>
                            Here are some of my personal projects. Some of them are modified from an assignment from my past courses. I also added some new features to these projects. Many other projects have not been documented, so they are not currently available here, but will be available in the future.
                        </Row>
                        <Row>
                            {project_ls_temp}
                        </Row>
                        <Row style={{ paddingTop: '5%', justifyContent: 'center' }}>
                            <p className="italic_text header_text_normal">
                                Some projects that are still under documentation process will be added here.
                            </p>
                        </Row>
                        <Row>
                            <div style={{ paddingBottom: '10%' }}></div>
                        </Row>
                    </>
                }
            </Container>
        </>
    }
}

export default Projects;