import './App.css';
import React from 'react';
import { Form, Button, Container, Row, Col, Modal, Jumbotron, Image, Alert } from 'react-bootstrap';
import profileImage from "./assets/pp.jpeg";
import styles from './style.css';
import ReCAPTCHA from "react-google-recaptcha";
//import bgImage from "./assets/bg.png";

const ColoredLine = ({ color, height }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: height,
            padding: 0,
            margin: 0
        }}
    />
);

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successComment: false,
            respModalShow: false,
            forgotCaptcha: false
        };

        this.name = React.createRef();
        this.email = React.createRef();
        this.comment = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.recaptchaRef = React.createRef();
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const token = this.recaptchaRef.current.getValue();

        if (token === "") {
            this.setState({
                forgotCaptcha: true
            });
            return
        }

        fetch('api/comment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.name.current.value,
                email: this.email.current.value,
                comment: this.comment.current.value,
                token: token,
            })
        }).then(async res => {
            await this.setState({ successComment: res.status === 200 });
            return res.json();
        }).then(res => {
            this.setState({
                respModalShow: true,
            })
        })
    }

    async handleCloseModal() {
        await this.setState({ respModalShow: false });
        window.location.reload();
    }

    // backgroundColor: '#007cf9'

    /*
        backgroundImage: `url(${bgImage})`,
        maxWidth: '100%',
        paddingTop: '5%',
        paddingBottom: '5%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    */

    render() {
        return <>
            <Container style={styles.no_top}>
                <Row style={styles.no_top}>
                    <Col style={{ marginTop: 0, paddingTop: 0, maxWidth: '100%' }}>
                        <Jumbotron fluid style={{
                            backgroundColor: '#e08f62',
                            maxWidth: '100%',
                            paddingTop: '2%',
                            paddingBottom: '2%',
                        }}>
                            <Container fluid>
                                <Row className="align-items-center">
                                    <Col md="1" />
                                    <Col md="4" className="d-none d-md-block" style={{ textAlign: "right" }}>
                                        <Image src={profileImage} roundedCircle responsive style={{ width: 'calc(8em + 8vw)' }} />
                                    </Col>
                                    <Col md="6" style={{ textAlign: 'left' }}>
                                        <div style={{ verticalAlign: "center" }}>
                                            <div className="header">Welcome to my personal website!</div>
                                            <ColoredLine color="white" height='3' />
                                            <div className="header_text">
                                                Hi! I'm Bloomest. Help yourself exploring this personal website of mine.
                                            </div>
                                            <ColoredLine color="white" height='3' />
                                            <div className="header_footer">
                                                <a href="/projects" class="header_link">Projects</a> | <a href="/resume" class="header_link">Resume</a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="1" />
                                </Row>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col md="8">
                        <div className="sub_header">
                            Summary
                        </div>
                        <div style={{ marginBottom: '3%' }}>
                            <ColoredLine color="black" size='5' />
                        </div>
                        <div>
                            <p>
                                I am a recent graduate from the University of Wisconsin - Madison, where I obtained bachelor degrees in Computer Science and Data Science.
                            </p>

                            <p>
                                I am a full-stack developer focusing more on the backend side. That being said, this website has some backend and database works on it.
                            You can learn more about this website <a href="/about">here</a>. I had some backend internships in the past, as well as some courses related to UI/UX, database management systems, algorithms, data structures,
                            AI, and more.
                            </p>

                            <p>
                            I am a kinesthetic learner who learns more from outside of the classroom. My personality trait is Architect (INTJ) according to this <a href="https://www.16personalities.com/intj-personality" target="_blank" rel="noopener noreferrer">test</a>, which I do believe this result is pretty accurate.
                            </p>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="sub_header">
                            Feedback
                        </div>
                        <div style={{ marginBottom: '3%' }}>
                            <ColoredLine color="black" size='5' />
                        </div>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="What should I call you?"
                                    ref={this.name}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email (optional)"
                                    ref={this.email}
                                />
                            </Form.Group>

                            <Form.Group controlId="formComment">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={3}
                                    ref={this.comment}
                                    placeholder="Any comment or feedback will do!"
                                />
                            </Form.Group>

                            <Form.Group controlId="formCaptcha">
                                <Alert variant="danger" show={this.state.forgotCaptcha} dismissible onClose={() => this.setState({ forgotCaptcha: false })}>
                                    Please solve the CAPTCHA!
                                </Alert>
                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey='6LfF9T8aAAAAAIt9nuf6C4golKvxr9aKHt0J5C5Q'
                                    style={{ display: "inline-block" }}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <div style={{ paddingBottom: '10%' }}></div>
                </Row>
            </Container>

            <Modal show={this.state.respModalShow} onHide={() => {window.location.reload()}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.successComment ? "Success" : "Oops..."}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.successComment ? "Thank you for your feedback!" : "Something went wrong. Please try again later!"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCloseModal}>
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    }
}

export default Home;