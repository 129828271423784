import './App.css';
import React from 'react';
import { Navbar, Nav, NavbarBrand, Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './Home.js';
import Projects from './Projects.js';
import About from './About.js';
import Resume from './Resume.js';

class App extends React.Component {
  render() {
    return <>
      <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
          <Navbar.Brand as={Link} to="/">Home</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/resume">Resume</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div>
          <Route exact path="/" component={() => <Home />} />
          <Route path="/projects" component={() => <Projects />} />
          <Route path="/about" component={() => <About />} />
          <Route path="/resume" component={() => <Resume />} />
        </div>
      </Router>

      <div className="fixed-bottom">
        <Navbar style={{ backgroundColor: "#333a40" }}>
          <Container style={{alignContent: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            <NavbarBrand style={{ color: "#ffffff" }}>
              <div className="footer_text">
                Design & Developed by: Bloomest Jansen Chandra
              </div>
            </NavbarBrand>
          </Container>
        </Navbar>
      </div>
    </>
  }
}

export default App;
